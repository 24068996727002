import React, { useContext } from "react";
import SectionTitle from "../elements/SectionTitle";
import * as styles from "../../sass/detailed.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { AnalyticsContext } from "../../contexts/AnalyticsContext";

function DetailedHighlights({ title, highlightsData }) {
  const data = useStaticQuery(graphql`
    query HighlightsQuery {
      DSAP_IMG: file(relativePath: { eq: "mingli_dsap_research.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      CF_IMG: file(relativePath: { eq: "mingli_cf_research.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      RICE_IMG: file(relativePath: { eq: "mingli_rice_research.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      SWAP_LOGO_IMG: file(relativePath: { eq: "swap-logo.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      QWISELY_LOGO_IMG: file(relativePath: { eq: "qwisely-logo.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      CATALYTICSINC_LOGO_IMG: file(
        relativePath: { eq: "catalyticsinc-logo.jpg" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      WEBSITESENSEI_LOGO_IMG: file(
        relativePath: { eq: "websitesensei-logo.jpg" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  const { customAnalytics } = useContext(AnalyticsContext);
  const { sendEvent } = customAnalytics;

  return (
    <section id="services">
      <div className="container">
        <SectionTitle title={title} />
        {highlightsData.map((highlight) => {
          return (
            <div className={`${styles.highlightsRow} row`} key={highlight.id}>
              <div className="col-md-6">
                <div
                  className={`${styles.detailedImgItem} rounded shadow-dark text-center`}
                >
                  {data[highlight.img] ? (
                    <GatsbyImage
                      image={
                        data[highlight.img].childImageSharp.gatsbyImageData
                      }
                      alt={highlight.alt}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <p className={styles.title}>{highlight.title}</p>
                <p className={styles.subtitle}>
                  <a
                    href={highlight.link}
                    onClick={(evt) => {
                      sendEvent({
                        action: "click",
                        target: {
                          text: evt.target.innerText,
                          tagName: evt.target.tagName,
                          className: evt.target.className,
                          href: evt.target.href,
                        },
                      });
                    }}
                  >
                    {highlight.subtitle}
                  </a>
                </p>
                <p className={styles.time}>{highlight.time}</p>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
                <div
                  dangerouslySetInnerHTML={{ __html: highlight.text }}
                  onClick={(evt) => {
                    sendEvent({
                      action: "click",
                      target: {
                        text: evt.target.innerText,
                        tagName: evt.target.tagName,
                        className: evt.target.className,
                        href: evt.target.href,
                      },
                    });
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default DetailedHighlights;
