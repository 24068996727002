export const publicationHighlights = [
  {
    id: 1,
    img: "DSAP_IMG",
    alt: "Data Sharing Agreement Privacy Ontology",
    title: "Privacy Enforcement in Health Research",
    subtitle: "First author 🔗",
    link: "https://ceur-ws.org/Vol-2275/paper3.pdf",
    text:
      "<p>In this research, we devised a new method of logging and auditing of privacy events described by medical research data sharing agreements (DSAs).</p><p>The logging is enabled through the engineering of a Data Sharing Agreement Privacy Ontology (DSAP), a flexible and extensible semantic model of privacy constraints. DSAP provides the necessary vocabulary and structure to log privacy events in a linked-data based audit log. We also demonstrate auditing capabilities through querying this audit log to answer privacy questions relevant to health research DSAs.</p>",
  },
  {
    id: 2,
    img: "CF_IMG",
    alt: "SLC6A14 ASL height confocal microscopy",
    title: "Genetic Modifiers of Cystic Fibrosis",
    subtitle: "Co-author 🔗",
    link: "https://www.atsjournals.org/doi/full/10.1165/rcmb.2019-0094OC",
    text:
      "<p>In this research, we uncovered a novel therapeutic target for the development of companion therapy in Cystic Fibrosis.</p><p>Using quantitative real-time PCR, western blotting, radiotracing, pharmacological, and advanced optical microscopy methods, we demonstrate that increased SLC6A14 (soluable carrier family 6, subfamily A, member 14) activity enhances CFTR (Cystic Fibrosis Transmembrane Conductance Regulator) function in patient-derived airway cultures via a nitrous oxide biochemical pathway.</p>",
  },
  {
    id: 3,
    img: "RICE_IMG",
    alt: "NH4 and NH3 research",
    title: "Nitrogen Transport in Plant Roots",
    subtitle: "Co-author 🔗",
    link: "https://academic.oup.com/plphys/article/163/4/1859/6111115",
    text:
      "<p>In this research, we contributed to our understanding of the fundamental mechanisms behind soil NH<sub>3</sub>/NH<sub>4</sub><sup>+</sup> toxicity in higher plants.</p><p>Using radiotracing, electrophysiological, pharmacological, respiratory, and histochemical techniques, we demonstrate gaseous NH<sub>3</sub> is the main nitrogen species of the NH<sub>3</sub>/NH<sub>4</sub><sup>+</sup> conjugate pair to be responsible for nitrogen futile transmembrane cycling toxicity in plant root cells. Our study helps to guide the development of new agricultural methods to improve crop production, reduce forest decline, and maintain biodiversity.</p>",
  },
];
